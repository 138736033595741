@charset "utf-8";

/* CSS Document */

.ddp-layout-lnb.ddp-selected {
  /* background-color: #fff !important;
    color: #535763 !important; */
}

.ddp-ui-help {
  display: none !important;
}

.ddp-ui-top-info {
  /* background-color: $primary !important; */
  background-color: $primary !important;
}

/************************************************************************************************************

	1. class명 정의

	(Project)-(Type)-(Name)

	xx-ui-xxx       : 기본 (아래 유형에 없는 경우)

	xx-layout-xxx   : 레이아웃
	xx-fixed-xxx    : 고정
	xx-wrap-xxx     : Wrap

	xx-layer-xxx    : 레이어 팝업
	xx-table-xxx    : 테이블
	xx-box-xxx      : 박스
	xx-list-xxx     : 목록
	xx-form-xxx     : Form형태 (입력, 수정, 로그인)
	xx-btn-xxx      : 버튼
	xx-icon-xxx     : 아이콘
	xx-img-xxx      : 이미지
	xx-bg-xxx       : 배경 이미지, 배경색
	xx-color-xxx    : 텍스트 색상
	xx-type-xxx     : 구분 (클래스 명이 같은 경우 type 클래스를 사용하여 구분)
	xx-status-xxx   : 상태값
	xx-data-xxx     : Data 값

	xx-input-xxx    : Input box
	xx-select-xxx   : Select box
	xx-textarea-xxx : Textarea
	xx-checkbox     : Checkbox
	xx-radio        : Radio

	xx-nodata       : 데이터 없는 경우
	xx-loading      : 로딩바

	xx-selected     : 선택, 활성
	xx-disabled     : 비활성

	2. css 작성 순서
	.class {display position left right top bottom float width height margin padding ... border background overflow z-index}
	.class {
		-webkit-border-radius:;
		-moz-border-radius:;
		-o-border-radius:;
		-ms-border-radius:;
		border-radius:;
	}

	* '...' 부분은 자유 배치


************************************************************************************************************/

/************************************************************************************************************
   Web Font
************************************************************************************************************/

/***********************************************************************************************************
	기본설정
************************************************************************************************************/

// * {
//   margin: 0;
//   padding: 0;
//   box-sizing: content-box;
//   line-height: 1.3em;

//   &:focus {
//     outline: none;
//   }
// }

html {
  width: 100%;
  height: 100%;
  min-width: 1200px;
  line-height: 1.3em;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  -ms-text-size-adjust: 100%;
  -webkit-text-size-adjust: 100%;
  -webkit-user-select: text;
  -moz-user-select: text;
  -ms-user-select: text;
  user-select: text;
}

body {
  font-family: 'SpoqaHanSans', sans-serif;
  width: 100%;
  min-width: 1200px;
  line-height: 1.3em;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  -ms-text-size-adjust: 100%;
  -webkit-text-size-adjust: 100%;
  -webkit-user-select: text;
  -moz-user-select: text;
  -ms-user-select: text;
  user-select: text;
  margin: 0;
  padding: 0;
  font-size: 12px;
  color: #4b515b;
}

header,
footer,
nav {
  display: block;
}

ul,
ol {
  list-style: none;
}

img,
button {
  border: 0 none;
  font-family: 'SpoqaHanSans', sans-serif;
}

table {
  width: 100%;
  border-spacing: 0px;
  border-collapse: separate;
}

em {
  font-style: italic;
}

u {
  text-decoration: underline;
  color: #707070;
}

a {
  &:hover {
    text-decoration: none;
    color: #707070;
  }

  outline: 0;
}

textarea {
  resize: none;
}

input,
select,
textarea {
  outline: none;
  box-shadow: none;
}

input {
  line-height: 1.3em;
  word-break: keep-all;
  font-family: sans-serif;

  &::-ms-clear {
    display: none;
  }

  &::placeholder,
  &::-webkit-input-placeholder,
  &:-ms-input-placeholder {
    color: #b7b9c2;
    font-family: sans-serif;
    line-height: 1.3em;
  }

  &[type='file'] {
    cursor: pointer;
  }

  &[type='number'] {
    &::-webkit-inner-spin-button,
    &::-webkit-outer-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }

    -moz-appearance: textfield;
  }
}

body * {
  word-break: keep-all;
  word-wrap: break-word;
  text-shadow: none;
}

label {
  margin-bottom: 0px;
  font-weight: normal;
}

button:focus {
  outline: none;
}

label {
  max-width: inherit;
}

pre {
  padding: 0px;
  margin: 0px;
  border: none;
  background: none;
  border-radius: 0px;

  code {
    background: none;
  }
}

/*body.ddp-body {background-color:#e7e7ea}*/

em {
  &.ddp-bg-back {
    display: block;
    position: fixed;
    left: 0;
    right: 0;
    bottom: 0;
    top: 0;
    background-color: #fefefe;

    &.type-bg {
      background-color: #eff1f4;
    }
  }

  &[class*='ddp-icon-'] {
    font-size: 0px;
    vertical-align: middle;
  }
}

textarea {
  &::-webkit-input-placeholder,
  &:-moz-placeholder,
  &::-moz-placeholder,
  &:-ms-input-placeholder {
    color: #a7adb2;
  }
}

/***********************************************************************************************************
	col
************************************************************************************************************/

[class*='ddp-col-'] {
  float: left;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  -o-box-sizing: border-box;
  -ms-box-sizing: border-box;
  box-sizing: border-box;
}

.ddp-col-12 {
  width: 100%;
}

.ddp-col-11 {
  width: 91.66666667% !important;
}

.ddp-col-10 {
  width: 83.33333333% !important;
}

.ddp-col-9 {
  width: 75% !important;
}

.ddp-col-8 {
  width: 66.66666667% !important;
}

.ddp-col-7 {
  width: 58.33333333% !important;
}

.ddp-col-6 {
  width: 50% !important;
}

.ddp-col-5 {
  width: 41.66666667% !important;
}

.ddp-col-4 {
  width: 33.33333333% !important;
}

.ddp-col-3 {
  width: 25% !important;
}

.ddp-col-2 {
  width: 16.66666667% !important;
}

.ddp-col-1 {
  width: 8.33333333% !important;
}

.ddp-part20 {
  float: left;
  width: 20%;
}

.ddp-part80 {
  float: left;
  width: 80%;
}

.ddp-clear {
  &:before {
    display: table;
    content: '';
  }

  &:after {
    display: table;
    content: '';
    clear: both;
  }
}

/***********************************************************************************************************
    HEADER
************************************************************************************************************/

.ddp-layout-wrap {
  height: 100%;
}

.ddp-layout-header {
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  height: 55px;
  border-top: 1px solid #fff;
  border-bottom: 1px solid #d7d9de;
  background-color: #fff;
  z-index: 125;
  box-sizing: border-box;

  .ddp-ui-headright {
    position: absolute;
    top: 0;
    right: 22px;
    z-index: 31;
  }

  .ddp-wrap-myinfo {
    float: left;
    position: relative;
  }

  .ddp-ui-searcing {
    position: relative;
    float: left;
    width: 20px;
    background: none;
    padding: 0;
    margin-top: 9px;
    height: 37px;
    margin-right: 15px;
    box-sizing: border-box;

    &.ddp-selected {
      width: 476px;
      padding: 0 15px 0 45px;
      background-color: #f6f6f7;
    }

    .ddp-box-search {
      display: none;
    }

    &.ddp-selected .ddp-box-search {
      display: block;
    }

    .ddp-box-search {
      &.ddp-enter-ing {
        padding-right: 20px;
      }

      &.ddp-enter-finish {
        padding-right: 60px;
      }

      &.ddp-enter-ing .ddp-ui-buttons .ddp-btn-remove {
        display: block;
      }

      &.ddp-enter-finish .ddp-ui-buttons {
        .ddp-btn-remove,
        .ddp-btn-refresh {
          display: block;
        }
      }

      .ddp-ui-buttons {
        position: absolute;
        top: 12px;
        right: 14px;

        .ddp-btn-refresh {
          display: none;
          float: left;
          width: 14px;
          height: 13px;
          background: url(/assets/bi/images/btn_refresh.png) no-repeat;
          background-position: left -34px;

          &:hover {
            background-position: -15px -34px;
          }
        }

        .ddp-btn-remove {
          display: none;
          position: relative;
          top: 1px;
          float: left;
          margin-left: 10px;
          width: 11px;
          height: 11px;
          background: url(/assets/bi/images/btn_close.png) no-repeat;
          background-position: -12px -58px;

          &:hover {
            background-position: left -58px;
          }
        }
      }
    }

    a.ddp-btn-search {
      display: inline-block;
      position: absolute;
      top: 50%;
      left: 14px;
      margin-top: -10px;
      width: 20px;
      height: 20px;
      background: url(/assets/bi/images/btn_search.png) no-repeat;
      box-sizing: border-box;
    }

    input {
      display: block;
      padding: 10px 0 9px 0;
      width: 100%;
      border: none;
      background: none;
      font-size: 14px;

      &::placeholder {
        color: #90969f;
      }
    }

    .ddp-box-popup {
      top: 44px;
      left: 0;
      right: 0;
      margin: 0;
      width: inherit;
      height: inherit;
      max-height: 486px;
      overflow: auto;

      span.ddp-label-type {
        display: block;
        padding: 10px 20px;
        color: #b7b9c2;
        font-size: 12px;
      }

      ul.ddp-list-lately li a {
        display: block;
        position: relative;
        padding: 12px 77px 12px 20px;
        color: #4b515b;
        font-size: 14px;
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
        word-wrap: normal;

        .ddp-data-date {
          position: absolute;
          top: 13px;
          right: 20px;
          color: #b7b9c2;
          font-size: 12px;
        }

        &:hover {
          background-color: #f6f6f7;
        }

        strong {
          color: #666db7;
          font-weight: normal;
        }
      }
    }
  }

  a.ddp-data-photo {
    display: inline-block;
    float: left;
    width: 34px;
    height: 34px;
    margin: 10px 0 0 22px;
    border-radius: 50%;
    overflow: hidden;

    img {
      width: 100%;
      height: 100%;
    }
  }

  .ddp-wrap-myinfo.ddp-selected .ddp-ui-sub-myinfo {
    display: block;
    border: 1px solid $primary;
  }
}

.ddp-ui-sub-myinfo {
  display: none;
  position: absolute;
  top: 50px;
  right: 0;
  width: 280px;
  background-color: #fff;
  z-index: 30;
  white-space: nowrap;
  border: 1px solid #dcdfe1;
  box-shadow: 0px 2px 2px 0px rgba(0, 0, 0, 0.2);
  z-index: 127;

  .ddp-wrap-data {
    padding: 20px 30px 0 30px;
    background-color: #4d527d;
  }

  .ddp-data-name {
    display: block;
    font-size: 16px;
    color: #fff;
    font-weight: bold;
  }

  span {
    &.ddp-data-team {
      display: block;
      padding-bottom: 10px;
      color: #9c9c9c;
      font-size: 13px;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    }

    &.ddp-data-mail {
      display: block;
      padding-bottom: 22px;
      color: #c1cef1;
      font-size: 13px;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    }
  }

  .ddp-list-sub-myinfo {
    padding: 15px 0;

    li {
      position: relative;
      float: inherit;

      a {
        display: block;
        position: relative;
        width: 100%;
        height: inherit;
        padding: 10px 0 10px 27px;
        margin: 0;
        color: #444;
        font-size: 14px;
        line-height: 1.3em;
        overflow: inherit;
        box-sizing: border-box;
      }

      &:hover a {
        font-weight: bold;
        background: none;
      }

      .ddp-hover-status {
        position: absolute;
        top: 12px;
        right: 30px;

        .ddp-status-active {
          font-weight: normal;
        }
      }

      ul.ddp-list-popup li {
        padding: 0;

        a {
          padding: 4px 30px 4px 14px;
          border-left: none;
          font-weight: normal;

          &:hover {
            font-weight: normal;
            background-color: #f6f6f7;
          }
        }
      }

      a {
        .ddp-txt-list {
          display: block;
          position: relative;
          padding-left: 22px;
        }

        .ddp-icon-info-profile {
          width: 12px;
          height: 12px;
          margin-top: -6px;
        }

        .ddp-icon-info-language {
          width: 15px;
          height: 15px;
          margin-top: -8px;
          background-position: left -13px;
        }

        .ddp-icon-info-theme {
          width: 11px;
          height: 13px;
          margin-top: -7px;
          background-position: left -26px;
        }
      }

      .ddp-theme-check {
        position: absolute;
        top: 50%;
        right: 32px;
        margin-top: -8px;

        label {
          float: left;
          margin-left: 8px;
          cursor: pointer;

          i {
            display: inline-block;
            position: relative;
            width: 16px;
            height: 16px;
            background-color: $primary;
          }

          &.type-dark i {
            background-color: #4b515b;
          }

          input {
            display: none;

            &[type='checkbox']:checked + i:before {
              display: inline-block;
              width: 11px;
              height: 8px;
              position: absolute;
              top: 50%;
              left: 50%;
              background: url(/assets/bi/images/icon_select2.png) no-repeat;
              background-position: -27px top;
              margin: -4px 0 0 -6px;
              content: '';
            }
          }
        }
      }
    }

    > li:hover > a:before {
      display: inline-block;
      position: absolute;
      top: 50%;
      left: 0;
      margin-top: -10px;
      height: 20px;
      width: 3px;
      background: $primary;
      content: '';
    }

    > li > a [class*='ddp-icon-'] {
      display: inline-block;
      position: absolute;
      top: 50%;
      left: 0;
      background: url('/assets/bi/images/icon_userinfo.png') no-repeat;
    }
  }

  .ddp-ui-part {
    border-top: 1px solid #e7e7ea;

    a {
      position: relative;
      display: block;
      padding: 15px 27px;
      color: #4b515b !important;
      font-size: 14px;

      &:hover {
        font-weight: bold;

        &:before {
          display: inline-block;
          position: absolute;
          top: 50%;
          left: 0;
          margin-top: -10px;
          height: 20px;
          width: 3px;
          background: $primary;
          content: '';
        }
      }
    }
  }
}

.ddp-layout-contents {
  position: relative;
  height: 100%;
  padding: 54px 0 0 0;
  box-sizing: border-box;

  .ddp-ui-contents-in {
    position: relative;
    padding: 0 0px;

    &.ddp-scroll {
      position: relative;
      height: 100%;
      box-sizing: border-box;

      .ddp-ui-contents-list {
        padding: 0 10px;
        box-sizing: border-box;
        overflow: auto;

        &.type-list {
          top: 54px;
        }

        .ddp-type-top-option .ddp-type-search input::placeholder {
          color: #90969f;
        }
      }
    }
  }
}

.ddp-layout-contents2 {
  position: relative;
  width: 100%;
  height: 100%;
  padding: 47px 0 0 0;
  margin-top: -48px;
  box-sizing: border-box;
}

.ddp-layout-contents-in {
  position: relative;
}

.ddp-ui-contentsin {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;

  &.ddp-full {
    top: 0;
    width: 100%;
  }
}

.ddp-layout-contents.ddp-type {
  padding-top: 0px;
  z-index: 65;

  .ddp-ui-contentsin {
    top: 0;
    z-index: 127;
  }
}

/***********************************************************************************************************
    검색결과
************************************************************************************************************/

.ddp-wrap-search-result {
  position: fixed;
  left: 0;
  right: 0;
  bottom: 0;
  top: 54px;
  background-color: #e7e6ea;
  z-index: 59;

  .ddp-ui-search-filter {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    padding-top: 25px;
    width: 239px;
    overflow: auto;
    overflow-x: hidden;

    .ddp-data-filter {
      padding: 40px 0 0 22px;

      &:first-of-type {
        padding-top: 0px;
      }

      .ddp-ui-filter-title {
        display: block;
        padding-bottom: 10px;
        color: #90969f;
        font-size: 12px;
      }
    }
  }
}

.ddp-ui-search-filter .ddp-data-filter ul.ddp-list-checktype {
  padding-left: 10px;

  li {
    padding: 7px 0;

    label .ddp-txt-checkbox {
      color: #4b515b;
      font-size: 13px;

      em.ddp-data-num {
        color: #90969f;
        font-size: 13px;
      }
    }
  }
}

.ddp-wrap-search-result .ddp-ui-search-result {
  position: absolute;
  top: 0;
  left: 239px;
  right: 0;
  bottom: 0;
  padding: 25px 22px 20px 0;
  overflow: auto;
}

.ddp-ui-search-result {
  .ddp-data-result {
    display: block;
    padding-bottom: 7px;
    color: #90969f;
    font-size: 12px;

    span.ddp-data-num {
      margin-left: 7px;
      color: #4b515b;
      font-size: 12px;
    }
  }

  ul.ddp-list-search-result {
    border: 1px solid #ddd;
    background-color: #fff;

    li {
      padding-left: 97px;
      cursor: pointer;

      &:hover {
        background-color: #f6f6f7;

        .ddp-wrap-navigation {
          background-color: #f6f6f7;
        }
      }

      .ddp-wrap-navigation {
        float: right;
        padding-right: 25px;
        background-color: #fff;

        span.ddp-data-navigation {
          position: relative;
          padding: 0 9px 0 14px;

          &:before {
            display: inline-block;
            content: '';
            position: absolute;
            top: 50%;
            left: 0;
            margin: -4px 0 0 0;
            width: 4px;
            height: 7px;
            background: url(/assets/bi/images/icon_dataview.png) no-repeat;
            background-position: left -11px;
          }

          &:first-of-type:before {
            display: none;
          }
        }
      }

      .ddp-data-type {
        position: absolute;
        left: 0;
        top: 0;
        bottom: 0;
        width: 97px;
        text-align: center;

        em.ddp-icon-folder {
          left: 50%;
          margin-left: -8px;
        }
      }

      .ddp-wrap-tag {
        padding: 11px 0 10px 0;
      }

      .ddp-box-tag {
        display: inline-block;
        float: inherit;
        margin-right: 0;
        line-height: normal;
      }

      .ddp-wrap-detailname span.ddp-data-detailname {
        &.ddp-data-new {
          position: relative;
          padding-right: 40px;
          max-width: 100%;
          box-sizing: border-box;
        }

        em.ddp-icon-new {
          display: none;
        }

        strong {
          font-weight: normal;
          color: #666db7;
        }

        &.ddp-data-new em.ddp-icon-new {
          display: block;
          position: absolute;
          top: 50%;
          right: 0;
          margin-top: -6px;
          line-height: normal;
          background-color: #fff;
        }
      }

      &:hover .ddp-wrap-detailname span.ddp-data-detailname.ddp-data-new em.ddp-icon-new {
        background-color: #f6f6f7;
      }

      .ddp-wrap-detailname span.ddp-data-summary {
        margin-left: 5px;
      }
    }
  }
}

/***********************************************************************************************************
    LNB
************************************************************************************************************/

.ddp-layout-lnb {
  position: fixed;
  left: 0;
  top: 0;
  width: 0;
  border-right: 2px solid #4c515a;
  box-sizing: border-box;
  text-indent: -30000px;

  a.ddp-btn-lnbmenu {
    display: inline-block;
    position: absolute;
    top: 0;
    left: 0;
    width: 49px;
    height: 55px;

    &:before {
      display: inline-block;
      content: '';
      position: absolute;
      top: 50%;
      left: 23px;
      margin: -6px 0 0 0;
      width: 16px;
      height: 12px;
      background: url(/assets/bi/images/btn_lnbmenu.png) no-repeat;
    }
  }

  .ddp-h1-logo {
    position: absolute;
    top: 21px;
    left: 52px;
    text-indent: 0;

    a {
      display: inline-block;
      width: 204px;
      height: 14px;

      svg {
        height: 100%;
      }

      .st10 {
        fill: #646c77;
      }

      .st11 {
        fill: #c3c7d2;
      }
    }
  }

  &.ddp-selected {
    .ddp-h1-logo a {
      .st10 {
        fill: #989ea6;
      }

      .st11 {
        fill: #595f6a;
      }
    }

    position: fixed;
    left: 0;
    top: 0;
    bottom: 0;
    width: 288px;
    background-color: #35393f;
    text-indent: 0;

    a.ddp-btn-lnbmenu:before {
      background-position: left -13px;
    }

    ul.ddp-list-lnb {
      display: block;
    }
  }
}

ul.ddp-list-lnb {
  display: none;
  position: absolute;
  top: 55px;
  left: 0;
  right: 0;
  bottom: 174px;
  overflow-y: auto;

  li {
    .ddp-wrap-listmenu {
      position: relative;

      &:after {
        display: inline-block;
        content: '';
        position: absolute;
        bottom: 0;
        left: 0;
        right: 0;
        border-bottom: 1px solid #3e434b;
      }
    }

    a {
      display: block;
      position: relative;

      span {
        &.ddp-txt-lnbmenu {
          display: block;
          margin: 0 0 0 23px;
          padding: 23px 44px 23px 0;
          font-size: 16px;
          color: #8a8f9b;
          font-weight: bold;
        }

        &.ddp-tag-beta {
          display: inline-block;
          position: relative;
          top: 2px;
          padding: 0 4px;
          margin-left: 3px;
          border-radius: 2px;
          border: solid 1px #909ae5;
          font-size: 11px;
          font-weight: normal;
          color: #909ae5;
          vertical-align: top;
        }
      }
    }

    &.ddp-selected a span.ddp-txt-lnbmenu {
      color: #e7e7ea;
    }

    a span.ddp-txt-lnbmenu span.ddp-link-lnbmenu {
      display: block;
      font-size: 16px;
      color: #8a8f9b;
      font-weight: bold;
      cursor: pointer;
    }

    &.ddp-selected a span.ddp-txt-lnbmenu span.ddp-link-lnbmenu {
      color: #e7e7ea;
    }

    a {
      span.ddp-txt-lnbmenu {
        span.ddp-link-lnbmenu em {
          &.ddp-icon-lnbplus {
            display: inline-block;
            position: relative;
            top: 2px;
            float: right;
            width: 14px;
            height: 14px;
            background: url(/assets/bi/images/icon_lnbplus.png) no-repeat;
            cursor: pointer;
            vertical-align: middle;
            margin-left: 6px;

            &:hover {
              background-position: -15px top;
            }
          }

          &.ddp-icon-move {
            display: inline-block;
            width: 14px;
            height: 13px;
            margin-left: 5px;
            background: url(/assets/bi/images/icon_move.png) no-repeat;
            vertical-align: middle;
          }
        }

        em.ddp-icon-plus {
          background-position: left -14px;
        }
      }

      em.ddp-icon-menuview {
        display: inline-block;
        position: absolute;
        top: 50%;
        right: 20px;
        width: 11px;
        height: 7px;
        margin-top: -4px;
        background: url(/assets/bi/images/icon_menuview.png) no-repeat;
      }
    }

    &.ddp-selected {
      background-color: #2a2d33;

      a em.ddp-icon-menuview {
        display: none;
      }
    }

    a:hover {
      background-color: #2a2d33;
    }

    &.ddp-selected .ddp-list-sub {
      display: block;
      padding-bottom: 19px;
    }

    .ddp-list-sub {
      display: none;

      .ddp-txt-title {
        display: block;
        position: relative;
        padding: 10px 40px 10px 56px;
        font-size: 14px;
        color: #b7b9c2;
        cursor: pointer;

        &.ddp-selected {
          font-weight: bold;
          background-color: #212429;

          em.ddp-icon-view {
            display: block;
          }
        }

        &:hover {
          background-color: #212429;

          em.ddp-icon-view {
            display: block;
          }
        }

        em {
          &.ddp-icon-view {
            display: none;
            position: absolute;
            top: 0;
            right: 0;
            width: 35px;
            height: 35px;

            &:before {
              display: inline-block;
              content: '';
              position: absolute;
              top: 50%;
              left: 50%;
              margin: -4px 0 0 -2px;
              width: 4px;
              height: 7px;
              background: url(/assets/bi/images/icon_dataview.png) no-repeat;
              background-position: left -19px;
            }

            &:hover {
              background-color: #181b20;
            }
          }

          &.ddp-icon-personal {
            display: inline-block;
            position: absolute;
            top: 50%;
            left: 30px;
            width: 11px;
            height: 13px;
            margin-top: -7px;
            background: url(/assets/bi/images/icon_lnb.png) no-repeat;
            vertical-align: middle;
          }
        }
      }

      .ddp-wrap-favorites {
        .ddp-label-type {
          display: block;
          padding: 25px 23px 5px 23px;
          font-size: 12px;
          font-weight: bold;
          color: #6b6e77;
        }

        .ddp-txt-info {
          display: block;
          padding: 10px 23px 0 23px;
          color: #6b6e77;
          font-size: 13px;

          .ddp-link-type a {
            margin: 0 -25px;
          }
        }
      }

      .ddp-link-type {
        margin: 8px 0 0 0;
        padding: 0 25px;

        a {
          display: block;
          padding: 7px 0 7px 0;
          color: #90969f;
          font-size: 13px;
          text-align: center;
          background-color: #35393f;
        }
      }
    }

    ul {
      &.ddp-list-submenu li {
        position: relative;

        &.ddp-disabled {
          opacity: 0.3;

          &:after {
            display: inline-block;
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            cursor: no-drop;
            content: '';
          }
        }

        a {
          position: relative;
          padding-right: 35px;
          height: 36px;

          em {
            &.ddp-icon-share {
              display: inline-block;
              position: absolute;
              top: 50%;
              left: 30px;
              width: 17px;
              height: 12px;
              margin-top: -6px;
              background: url(/assets/bi/images/icon_lnb.png) no-repeat;
              background-position: left -14px;
            }

            &.ddp-icon-view {
              display: none;
              position: absolute;
              top: 0;
              right: 0;
              width: 35px;
              height: 35px;

              &:before {
                display: inline-block;
                position: absolute;
                top: 50%;
                left: 50%;
                margin: -4px 0 0 -2px;
                width: 4px;
                height: 7px;
                background: url(/assets/bi/images/icon_dataview.png) no-repeat;
                background-position: left -19px;
                content: '';
              }

              &:hover {
                background-color: #181b20;
              }
            }
          }
        }

        &.ddp-selected em.ddp-icon-view {
          display: block;
          background-color: #212429;
        }

        a:hover {
          background-color: #212429;

          em.ddp-icon-view {
            display: block;
          }

          background-color: #212429;
        }

        &.ddp-selected a {
          background-color: #212429;
        }

        a span.ddp-data-listmenu {
          display: inline-block;
          max-width: 100%;
          position: relative;
          padding: 10px 0 10px 56px;
          font-size: 14px;
          color: #b7b9c2;
          white-space: nowrap;
          text-overflow: ellipsis;
          overflow: hidden;
          word-wrap: normal;
          box-sizing: border-box;

          em.ddp-icon-new {
            display: none;
            position: absolute;
            top: 12px;
            right: 0;
            font-size: 10px;
            color: #919ae3;

            &:before {
              display: inline-block;
              position: relative;
              top: -1px;
              content: '';
              width: 5px;
              height: 5px;
              margin-right: 4px;
              border-radius: 50%;
              vertical-align: middle;
              background-color: #919ae3;
            }
          }
        }

        &.ddp-selected a span.ddp-data-listmenu {
          font-weight: bold;
        }

        a {
          span.ddp-data-listmenu.ddp-new {
            padding-right: 38px;

            em.ddp-icon-new {
              display: block;
            }
          }

          .ddp-icon-afav {
            display: inline-block;
            width: 12px;
            height: 12px;
            position: absolute;
            top: 50%;
            right: 20px;
            margin-top: -6px;
            background: url(/assets/bi/images/icon_fav.png) no-repeat;
            background-position: left -18px;

            &.ddp-selected {
              background-position: -13px -18px;
            }
          }
        }
      }

      &.ddp-list-sublnb li {
        a {
          display: block;
          position: relative;
          padding: 10px 10px 10px 56px;
          color: #6b6e77;
          font-size: 14px;

          em.ddp-icon-dot {
            display: inline-block;
            position: absolute;
            top: 14px;
            left: 30px;
            width: 10px;
            height: 10px;
            background: url(/assets/bi/images/icon_lnbdot.png) no-repeat;
            background-position: left -3px;
          }
        }

        &.ddp-selected a {
          background-color: #212429;
        }

        a em.ddp-icon-data {
          display: inline-block;
          position: absolute;
          top: 50%;
          left: 28px;
          margin-top: -7px;
          width: 12px;
          height: 14px;
          margin-right: 12px;
          background: url(/assets/bi/images/icon_lnb.png) no-repeat;
          background-position: left -71px;
          vertical-align: middle;
        }

        &.ddp-selected a em.ddp-icon-dot {
          top: 18px;
          width: 10px;
          height: 2px;
          background-position: left top;
        }

        a:hover {
          background-color: #212429;
        }

        ul.ddp-list-sublnb-2depth {
          display: none;
        }

        &.ddp-selected ul.ddp-list-sublnb-2depth {
          display: block;
          margin-bottom: 10px;
        }

        ul.ddp-list-sublnb-2depth li a {
          color: #b7b9c2;
          font-size: 14px;
          background-color: #2a2d33;

          &:hover {
            background-color: #212429;
          }
        }
      }
    }
  }
}

.ddp-layout-lnb .ddp-ui-help {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  padding: 33px 0 5px;
  background-color: #35393f;

  .ddp-label-type {
    display: block;

    .ddp-fright {
      margin-right: 20px;
    }

    .ddp-txt-label {
      display: inline-block;
      padding: 7px 20px 7px 23px;
      color: rgba(144, 150, 159, 0.6);
      font-size: 12px;
    }
  }

  a.ddp-btn-box {
    display: inline-block;
    position: relative;
    padding: 6px 9px 6px 24px;
    margin-left: 4px;
    color: #90969f;
    font-size: 12px;
    border: 1px solid rgba(112, 123, 129, 0.3);
    border-radius: 2px;

    &:hover {
      border: 1px solid rgba(112, 123, 129, 1);
    }

    em {
      &.ddp-icon-manual,
      &.ddp-icon-global {
        display: inline-block;
        background: url(/assets/bi/images/icon_lnb.png) no-repeat;
      }

      &.ddp-icon-manual {
        position: absolute;
        top: 50%;
        left: 7px;
        margin-top: -7px;
        width: 13px;
        height: 14px;
        background-position: left -42px;
      }

      &.ddp-icon-global {
        position: absolute;
        top: 50%;
        left: 6px;
        width: 13px;
        height: 13px;
        margin-top: -7px;
        background-position: left -57px;
      }
    }
  }
}

.ddp-data-help {
  padding: 10px 23px 20px 23px;

  dl {
    dt {
      float: left;
      width: 90px;
      color: #90969f;
    }

    dd {
      display: block;
      overflow: hidden;
      color: #90969f;

      a {
        color: #90969f;
      }
    }
  }
}

.ddp-layout-lnb {
  .ddp-ui-help .ddp-viersion {
    padding: 0 0 20px 23px;
    font-size: 13px;
    color: #90969f;
    opacity: 0.6;
  }

  .ddp-data-help .ddp-label-type {
    padding: 0 0 4px 0;
    color: rgba(144, 150, 159, 0.6);
  }

  .ddp-data-version {
    padding: 0 23px 17px 23px;
    color: #90969f;
    font-size: 12px;
  }
}

/**************************************************************
        lnb folder
    **************************************************************/

.ddp-wrap-folder-nav {
  position: fixed;
  top: 54px;
  left: 288px;
  right: 0;
  bottom: 0;
  padding-top: 33px;
  z-index: 30;
  white-space: nowrap;
  overflow-x: auto;
  font-size: 0px;
  background-color: #fff;
}

.ddp-ui-title-nav {
  position: fixed;
  top: 54px;
  left: 288px;
  right: 0;
  height: 33px;
  padding-left: 18px;
  background-color: #90969f;
  box-sizing: border-box;
}

.ddp-list-locate {
  white-space: nowrap;
  word-wrap: normal;
  text-overflow: ellipsis;
  overflow: hidden;
  color: #fff;
  font-size: 13px;

  span {
    position: relative;
    padding-left: 14px;
    margin-left: 8px;
    line-height: 32px;

    &:before {
      display: inline-block;
      position: absolute;
      top: 50%;
      left: 0;
      right: 10px;
      width: 4px;
      height: 7px;
      margin-top: -3px;
      background: url(/assets/bi/images/icon_dataview.png) no-repeat;
      background-position: left -11px;
      content: '';
    }

    &:first-of-type {
      padding-left: 0px;
      margin-left: 0px;

      &:before {
        display: none;
      }
    }
  }
}

.ddp-ui-folder-nav {
  height: 100%;
  position: relative;
  z-index: 1;
}

.ddp-list-folder-nav {
  display: inline-block;
  width: 210px;
  height: 100%;
  border-right: 1px solid #ccc;
  background-color: #fff;
  overflow-y: auto;
  box-sizing: border-box;
}

ul.ddp-list-folder-nav li {
  position: relative;

  a {
    display: block;
    position: relative;
    padding: 4px 24px 5px 40px;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    color: #222;
    font-size: 12px;
  }

  &.ddp-list-folder {
    a em.ddp-box-view {
      display: inline-block;
      width: 24px;
      height: 24px;
      position: absolute;
      top: 0;
      right: 0;
    }

    &.ddp-selected a em.ddp-box-view {
      background-color: #d0d1d8;
    }

    a em.ddp-box-view {
      &:before {
        display: inline-block;
        content: '';
        position: absolute;
        top: 50%;
        left: 50%;
        margin: -4px 0 0 -2px;
        width: 4px;
        height: 7px;
        background: url(/assets/bi/images/icon_dataview.png) no-repeat;
        background-position: left -19px;
      }

      &:hover {
        background-color: #ccc;
      }
    }
  }

  &:nth-child(even) a {
    background-color: #fafafa;
  }

  em {
    &[class*='ddp-icon-'] {
      display: inline-block;
      position: absolute;
      top: 50%;
      left: 15px;
      background: url(/assets/bi/images/icon_gnb.png) no-repeat;
    }

    &.ddp-icon-folder {
      width: 15px;
      height: 13px;
      margin-top: -7px;
    }

    &.ddp-icon-workbook {
      width: 12px;
      height: 14px;
      background-position: left -14px;
      margin-top: -7px;
    }

    &.ddp-icon-notebook {
      width: 12px;
      height: 14px;
      background-position: left -29px;
      margin-top: -7px;
    }

    &.ddp-icon-workbench {
      width: 13px;
      height: 14px;
      background-position: left -44px;
      margin-top: -7px;
    }
  }

  &.ddp-selected a,
  a:hover {
    background-color: #e7e7ea;
  }
}

/**************************************************************
    팝업 레이아웃
**************************************************************/

/* 1 */

.ddp-wrap-layout-popup {
  position: fixed;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(208, 209, 216, 0.5);
  z-index: 1000;
  box-sizing: border-box;
  overflow: auto;

  &.ddp-scroll-popup {
    display: block;
    overflow: auto;

    .ddp-ui-layout-popup {
      display: table;
      width: 100%;
      height: 100%;
      text-align: center;
    }

    .ddp-pop-contents {
      display: table-cell;
      width: 100%;
      height: 100%;
      vertical-align: middle;
    }
  }
}

/* 2 */

.ddp-box-popup {
  display: inline-block;
  position: absolute;
  top: 25%;
  left: 0;
  right: 0;
  min-width: 416px;
  max-width: 550px;
  text-align: left;
  background-color: #fff;
  -moz-box-shadow: 0 0 20px rgba(0, 0, 0, 0.3);
  -webkit-box-shadow: 0 0 20px rgba(0, 0, 0, 0.3);
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.3);
  padding: 40px;
  margin: 0 auto;

  .ddp-pop-title {
    padding: 85px 70px 60px 70px;
    color: #4b515b;
    font-size: 28px;
    line-height: 40px;
    font-weight: 300;
    text-align: center;
  }

  .ddp-btn-close {
    display: inline-block;
    position: absolute;
    top: 20px;
    right: 20px;
    width: 23px;
    height: 23px;
    background: url(/assets/bi/images/btn_popclose.png) no-repeat;
  }

  .ddp-ui-buttons {
    text-align: center;

    a[class*='ddp-btn-type-'] {
      margin: 0 3px;
    }
  }
}

/* 3 */

.ddp-layout-popuptype {
  position: fixed;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  overflow: auto;
  z-index: 999;

  .ddp-ui-popup {
    position: absolute;
    top: 80px;
    left: 50%;
    bottom: 0;
    width: 960px;
    overflow-y: auto;
    margin-left: -480px;
    background-color: #fff;
    z-index: 127;

    .ddp-ui-popup-title {
      position: relative;
      padding: 44px 280px 44px 50px;
      text-align: left;
      overflow: hidden;

      .ddp-txt-sub-title {
        position: absolute;
        bottom: 20px;
        left: 50px;
        color: #b7b9c2;
        font-size: 13px;
        font-weight: 300;
      }

      span.ddp-txt-title-name {
        position: relative;
        width: 100%;
        line-height: 32px;
        box-sizing: border-box;
        color: #222;
        font-size: 18px;

        span.ddp-txt-thin {
          font-weight: 300;
        }

        .ddp-txt-det {
          position: relative;
          padding: 0 0 0 14px;
          margin-left: 12px;
          color: #b7b9c2;
          font-size: 14px;

          &:before {
            display: inline-block;
            position: absolute;
            top: 50%;
            left: 0;
            margin-top: -7px;
            content: '';
            height: 14px;
            border-left: 2px solid #dbdce0;
          }
        }

        .ddp-txt-det2 {
          position: absolute;
          top: 100%;
          left: 0;
          margin-top: 7px;
          color: #b7b9c2;
          font-size: 14px;
          white-space: nowrap;
        }
      }

      .ddp-ui-pop-buttons {
        position: absolute;
        top: 49px;
        right: 47px;
        z-index: 3;

        .ddp-btn-pop {
          float: Left;

          + .ddp-btn-pop {
            margin-left: 4px;
          }
        }
      }
    }
  }

  .ddp-ui-info-det {
    position: absolute;
    bottom: 20px;
    left: 0;
    right: 0;
    padding: 0 50px;
    color: #e70000;
    font-size: 14px;

    em.ddp-icon-error-info {
      display: inline-block;
      margin-right: 5px;
      position: relative;
      top: -1px;
      width: 15px;
      height: 15px;
      background: url(/assets/bi/images/icon_info.png) no-repeat;
    }
  }

  &.type-flex {
    display: flex;
    justify-content: center;
    align-items: center;
  }
}

.ddp-ui-flex-popup {
  display: flex;
  flex-direction: column;
  width: 960px;
  max-height: 100%;
  position: relative;
  box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.3);
  background-color: #fff;
  z-index: 127;

  .ddp-pop-title {
    padding: 29px 30px 30px 30px;
    color: #4b515b;
    font-size: 18px;

    .ddp-ui-buttons {
      position: absolute;
      right: 73px;
      top: 25px;

      .ddp-btn-pop {
        font-size: 13px;
      }
    }
  }

  .ddp-btn-close {
    display: inline-block;
    position: absolute;
    top: 29px;
    right: 30px;
    width: 23px;
    height: 23px;
    background: url(/assets/bi/images/btn_popclose.png) no-repeat;
  }

  .ddp-pop-contents {
    flex: auto;
    padding: 0 30px 30px 30px;
    overflow: auto;

    .ddp-table-detail2 {
      margin-left: -20px;
    }
  }
}

/* 4 */

.ddp-ui-layout-popup {
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  width: 100%;

  .ddp-scroll-popup {
    width: 100%;
    max-height: 100%;
    overflow: auto;
  }

  .ddp-box-popup.ddp-popup-type {
    position: relative;
    top: 0;
    left: 0;
    margin: 0;
    width: 558px;
    height: auto;
    background-color: #fff;
    -moz-box-shadow: 0 0 20px rgba(0, 0, 0, 0.3);
    -webkit-box-shadow: 0 0 20px rgba(0, 0, 0, 0.3);
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.3);

    .ddp-pop-title {
      padding: 85px 0 0 0;
    }

    .ddp-ui-buttons {
      padding-bottom: 80px;
    }
  }
}

.ddp-box-popup3 {
  display: inline-block;
  position: relative;
  top: 0;
  left: 0;
  margin: 0;
  width: 958px;
  height: auto;
  text-align: center;
  background-color: #fff;
  -moz-box-shadow: 0 0 20px rgba(0, 0, 0, 0.3);
  -webkit-box-shadow: 0 0 20px rgba(0, 0, 0, 0.3);
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.3);

  .ddp-pop-title {
    padding: 80px 0 0 0;
    min-height: 80px;
    color: #4b515b;
    font-size: 28px;
    font-weight: 300;
    text-align: center;

    .ddp-txt-pop-info {
      padding: 14px 0 0 0;
      font-size: 14px;
      color: #90969f;
      font-weight: normal;
    }
  }

  .ddp-pop-scroll {
    margin: 17px 0 5px 0;
    max-height: 333px;
    overflow: auto;
  }

  .ddp-btn-close {
    display: inline-block;
    position: absolute;
    top: 20px;
    right: 20px;
    width: 23px;
    height: 23px;
    background: url(/assets/bi/images/btn_popclose.png) no-repeat;
  }

  .ddp-box-detail {
    position: relative;
    display: inline-block;
    width: 464px;
    padding: 20px 0;
    text-align: left;

    &.ddp-type [class*='ddp-wrap-edit'] {
      margin-top: 20px;

      &:first-of-type {
        margin-top: 0;
      }
    }

    .ddp-ui-high-option {
      padding: 20px 0;
      text-align: right;

      .ddp-link-high {
        text-decoration: underline;

        .ddp-icon-view {
          display: inline-block;
          margin-left: 12px;
          width: 7px;
          height: 4px;
          background: url('/assets/bi/images/icon_select.png') no-repeat;
          background-position: -24px 0;
          vertical-align: middle;
          content: '';
        }
      }
    }

    .ddp-wrap-high {
      &.ddp-selected .ddp-link-high .ddp-icon-view {
        transform: rotate(180deg);
      }

      .ddp-high-setting {
        display: none;
      }

      &.ddp-selected .ddp-high-setting {
        display: block;
      }
    }

    .ddp-ui-message {
      margin-top: 20px;
      margin-bottom: -30px;
    }
  }

  .ddp-ui-buttons {
    padding: 20px 0 80px 0;
  }

  .ddp-clear {
    margin: 0 -4px;

    [class*='ddp-col-'] {
      padding: 0 4px;
    }
  }

  .ddp-ui-buttons a[class*='ddp-btn-type-'] {
    margin: 0 3px;
  }

  &.ddp-box-fix {
    height: 720px;

    .ddp-ui-buttons {
      position: absolute;
      bottom: 0;
      left: 0;
      right: 0;
    }
  }
}

.ddp-wrap-layout-popup {
  &.ddp-wrap-edit .ddp-box-popup {
    width: 679px;

    .ddp-box-edit {
      height: 324px;
      margin-bottom: 20px;
      border-bottom: 1px solid #e7e7ea;
    }
  }

  &.type-small .ddp-table-cell {
    display: table-cell;
    vertical-align: middle;
    text-align: center;

    .ddp-box-popup {
      position: relative;
      top: 0;
      left: 0;
      margin: 0;
      display: inline-block;
      width: 400px;
      height: auto;

      .ddp-pop-title {
        padding: 0 30px 0 30px;
        font-size: 18px;
        text-align: left;
        line-height: 1.3em;
        font-weight: normal;
      }

      .ddp-pop-detail {
        margin: 0;
        padding: 15px 30px 0 30px;
        text-align: left;
      }

      .ddp-popup-text {
        padding: 40px 0;
        border-bottom: 1px solid rgba(231, 231, 234, 0.7);
      }

      .ddp-ui-buttons {
        padding: 15px 0;

        .ddp-btn-type-popup {
          min-width: 80px;
          padding: 6px 10px;
          box-sizing: border-box;
          font-size: 13px;
        }
      }
    }
  }

  &.ddp-wrap-edit .ddp-box-popup .ddp-ui-popup-title {
    text-align: left;
    font-size: 18px;
    padding: 10px 15px;
    overflow: hidden;

    em.ddp-icon-btntext {
      margin-top: -4px;
    }
  }
}

/**************************************************************
    텍스트 위젯 편집 팝업
**************************************************************/

/**************************************************************
    공통팝업 레이아웃
**************************************************************/

.ddp-box-popup.ddp-box-popup-type1 {
  .ddp-pop-title {
    padding: 140px 100px 43px 100px;
  }

  .ddp-pop-detail {
    margin: 0 100px 61px 100px;
    max-height: 150px;
    font-size: 14px;
    color: #90969f;
    text-align: center;
    line-height: 24px;
    overflow: auto;
  }
}

.ddp-box-popup3 .ddp-pop-detail {
  clear: both;
  padding: 35px 0px 0px 0px;
  font-size: 14px;
  color: #90969f;
  text-align: center;
  line-height: 24px;
}

.ddp-box-popup {
  &.ddp-box-popup-type2 .ddp-pop-title {
    padding: 190px 100px 52px 100px;
    height: 80px;
  }

  &.ddp-box-popup-type3 {
    .ddp-pop-title {
      padding: 80px 0 0 0;
    }

    .ddp-box-detail {
      display: table;
      table-layout: fixed;
      position: absolute;
      top: 180px;
      left: 0;
      right: 0;
      width: 100%;
      min-height: 240px;
      text-align: center;
      vertical-align: middle;

      .ddp-box-detail-in {
        display: table-cell;
        width: 100%;
        height: 100%;
        padding: 0 100px;
        vertical-align: middle;
        box-sizing: border-box;

        .ddp-txt-detail {
          max-height: 240px;
          overflow: auto;

          &.ddp-word-break {
            word-break: break-all;
          }
        }

        .ddp-box-edit {
          display: inline-block;
          width: 464px;
          text-align: left;

          &.ddp-type .ddp-wrap-edit3 + .ddp-wrap-edit3 {
            margin-top: 15px;
          }
        }
      }

      .ddp-box-form {
        display: inline-block;
        width: 464px;
        text-align: left;
      }
    }
  }

  .ddp-box-form .ddp-wrap-edit2 {
    margin-top: 30px;
  }

  &.ddp-box-popup-type3 {
    span.ddp-pop-detail {
      display: block;
      height: 48px;
      padding-bottom: 10px;
      color: #4b515b;
      font-size: 14px;
      line-height: 24px;

      em.ddp-icon-time2 {
        margin-right: 5px;
        vertical-align: middle;
      }
    }

    ul.ddp-list-type-input {
      display: inline-block;
      text-align: left;

      li {
        padding: 6px 0;

        .ddp-label-radio + .ddp-type-selectbox {
          position: relative;
          top: -5px;
        }

        &.ddp-list-inline {
          .ddp-label-radio.ddp-inline {
            margin-right: 9px;
          }

          .ddp-type-selectbox {
            width: 230px;
            box-sizing: border-box;
          }
        }
      }
    }

    .ddp-ui-buttons {
      position: absolute;
      left: 0;
      right: 0;
      bottom: 80px;
    }
  }
}

/**************************************************************
    팝업2 레이아웃
**************************************************************/

:not(.layout-horizontal).layout-static-active .ddp-type-contents {
  left: 240px;
}

.layout-horizontal .ddp-type-contents {
  top: 100px;
  left: 0;
}

.ddp-type-contents {
  &.ddp-scroll {
    .ddp-icon-name {
      box-shadow: 0 3px 5px rgba(0, 0, 0, 0.05);
    }

    > {
      .ddp-ui-buttons2,
      .ddp-ui-buttons {
        box-shadow: 0 -3px 5px rgba(0, 0, 0, 0.05);
      }
    }
  }

  position: fixed;
  left: 0;
  right: 0;
  bottom: 0;
  top: 55px;
  padding-top: 72px;
  padding-bottom: 55px;
  text-align: center;
  background-color: #fff;
  overflow-y: auto;
  z-index: 127;
}

.ddp-type-contents2 {
  background-color: #fff;
}

.layout-static-active .ddp-type-contents3 {
  left: 240px;
}

.ddp-type-contents3 {
  position: fixed;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  background-color: #fff;
  overflow-y: auto;
  z-index: 127;
}

.ddp-type-contents {
  a.ddp-btn-close {
    position: fixed;
    top: 20px;
    right: 20px;
    display: inline-block;
    width: 23px;
    height: 23px;
    background: url(/assets/bi/images/btn_popclose.png) no-repeat;
    z-index: 90;
  }

  .ddp-ui-name2 {
    &.ddp-type {
      padding: 0 0 33px 0;
      margin-top: -20px;
    }

    &.ddp-type2 {
      margin-bottom: 0;
    }

    &.ddp-type3 {
      padding-top: 13px;
      padding-bottom: 15px;
      margin-bottom: 25px;
    }

    display: inline-block;
    position: relative;
    padding: 54px 16px 33px 16px;
    margin-bottom: 52px;
    color: $primary;
    font-size: 18px;
  }

  &.ddp-resize2.ddp-type2 .ddp-ui-name2 {
    padding: 2px 0 15px 0;
    margin-bottom: 0;
  }

  &.ddp-resize.ddp-type {
    padding: 38px 0 78px 0;

    .ddp-ui-name2.ddp-type3 .ddp-txt-info {
      display: block;
      color: #90969f;
      font-size: 14px;
      text-align: center;
    }
  }

  .ddp-ui-name2 {
    .ddp-icon-newlink2 {
      display: inline-block;
      position: absolute;
      top: 0;
      left: 50%;
      margin-left: -15px;
      width: 30px;
      height: 30px;
      background: url(/assets/bi/images/icon_popup.png) no-repeat;
    }

    .ddp-icon-workbench {
      display: inline-block;
      position: absolute;
      top: 0;
      left: 50%;
      margin-left: -15px;
      width: 30px;
      height: 30px;
      background: url(/assets/bi/images/icon_popup.png) no-repeat;
      background-position: -31px top;
    }

    em {
      &[class*='ddp-bg-order-line2'] {
        display: inline-block;
        position: absolute;
        bottom: -4px;
        left: 50%;
        width: 38px;
        height: 9px;
        margin-left: -19px;
        background: url(/assets/bi/images/bg_order_line2.png) no-repeat;
      }

      &.ddp-bg-order-line2-type2 {
        background-position: left -10px;
      }

      &[class*='ddp-bg-order-line3'] {
        display: inline-block;
        position: absolute;
        bottom: -4px;
        left: 50%;
        width: 70px;
        height: 9px;
        margin-left: -34px;
        background: url(/assets/bi/images/bg_order_line.png) no-repeat;
      }

      &.ddp-bg-order-line3-type2 {
        background-position: left -10px;
      }

      &.ddp-bg-order-line3-type3 {
        background-position: left -20px;
      }

      &[class*='ddp-bg-order-line4'] {
        display: inline-block;
        position: absolute;
        bottom: -4px;
        left: 50%;
        width: 100px;
        height: 9px;
        margin-left: -50px;
        background: url(/assets/bi/images/bg_order_line4.png) no-repeat;
      }

      &.ddp-bg-order-line4-type2 {
        background-position: left -10px;
      }

      &.ddp-bg-order-line4-type3 {
        background-position: left -20px;
      }

      &.ddp-bg-order-line4-type4 {
        background-position: left -30px;
      }

      &[class*='ddp-bg-order-line5'] {
        display: inline-block;
        position: absolute;
        bottom: -4px;
        left: 50%;
        width: 129px;
        height: 9px;
        margin-left: -64px;
        background: url(/assets/bi/images/bg_order_line5.png) no-repeat;
      }

      &.ddp-bg-order-line5-type2 {
        background-position: left -10px;
      }

      &.ddp-bg-order-line5-type3 {
        background-position: left -20px;
      }

      &.ddp-bg-order-line5-type4 {
        background-position: left -30px;
      }

      &.ddp-bg-order-line5-type5 {
        background-position: left -40px;
      }

      &.ddp-bg-order-type {
        display: inline-block;
        position: absolute;
        bottom: -4px;
        left: 50%;
        width: 75px;
        margin-left: -38px;
        border-top: 1px solid $primary;
      }
    }
  }
}

.ddp-type-contents-in .ddp-txt-info {
  display: block;
  padding-bottom: 86px;
  color: #35393f;
  font-size: 28px;
  text-align: center;
  font-weight: 300;

  &.ddp-type {
    padding-bottom: 88px;
  }
}

.ddp-type-contents {
  .ddp-ui-buttons {
    padding-top: 20px;

    a[class*='ddp-btn-type-'] {
      margin: 0 2px;
    }

    &.type-shadow {
      box-shadow: 0 -3px 8px 0 rgba(0, 0, 0, 0.1);
    }
  }

  &.ddp-resize {
    overflow: hidden;

    .ddp-type-contents-in {
      position: absolute;
      top: 154px;
      left: 0;
      right: 0;
      bottom: 80px;

      &.ddp-type {
        top: 0;

        .ddp-type-multidata {
          top: 86px;
        }
      }
    }

    .ddp-box-popupcontents3 {
      position: absolute;
      top: 65px;
      left: 0;
      right: 0;
      bottom: 0;
      height: auto;
      margin-top: 0;
    }

    &.ddp-type {
      .ddp-box-popupcontents3 {
        top: 0;

        &.ddp-type-multidata.ddp-edit {
          bottom: -38px;
          overflow: hidden;
        }
      }

      .ddp-type-contents-in {
        top: 140px;
      }
    }

    .ddp-ui-buttons {
      position: absolute;
      bottom: 0;
      left: 0;
      right: 0;
      padding-bottom: 20px;
    }
  }

  &.ddp-resize2 {
    padding: 0;

    .ddp-icon-name {
      background-color: #fff;
      z-index: 70;

      .ddp-txt-info {
        display: block;
        padding-bottom: 86px;
        color: #35393f;
        font-size: 28px;
        text-align: center;
        font-weight: 300;
      }
    }

    &.ddp-type2 .ddp-icon-name {
      padding-top: 38px;
      padding-bottom: 25px;

      .ddp-txt-info {
        padding-bottom: 0;
        color: #90969f;
        font-size: 14px;
        font-weight: normal;
      }

      &.type-shadow {
        box-shadow: 0 3px 8px 0 rgba(0, 0, 0, 0.1);
      }
    }

    .ddp-ui-name2.ddp-type {
      position: fixed;
      padding-top: 40px;
      left: 0;
      right: 0;
      margin-top: 0;
      background-color: #fff;
      z-index: 20;
    }

    .ddp-box-popupcontents4 {
      padding: 120px 0 78px 0;

      > .ddp-wrap-edit2:first-of-type {
        margin-top: 0;
      }
    }

    > {
      .ddp-ui-buttons2,
      .ddp-ui-buttons {
        position: fixed;
        bottom: 0;
        left: 0;
        right: 0;
        padding-bottom: 20px;
        background-color: #fff;
      }
    }

    .ddp-ui-buttons.type-fix {
      position: fixed;
      bottom: 0;
      left: 0;
      right: 0;
      padding-bottom: 20px;
      background-color: #fff;
    }

    .ddp-type-contents-in {
      position: relative;
      min-height: 100%;
      box-sizing: border-box;

      &.ddp-scroll {
        padding: 0 !important;
        max-height: calc(100vh - 250px);
        min-height: auto;
        overflow: auto;
      }
    }

    &.ddp-type2 .ddp-type-contents-in {
      box-sizing: border-box;
    }
  }
}

.ddp-type-contents-in.ddp-size-resize .ddp-box-grid-detail {
  bottom: 1px;
}

.ddp-type-contents.ddp-resize2 {
  .ddp-type-contents-in.ddp-full {
    height: 100%;
  }

  .ddp-box-popupcontents3 {
    padding: 160px 0 130px 0;
  }

  &.ddp-type2 .ddp-box-popupcontents3 {
    padding-top: 138px;
  }

  .ddp-type-contents-in .ddp-box-popupcontents3 {
    padding: 0;
  }

  &.ddp-type .ddp-type-contents-in {
    padding-top: 270px;

    &.ddp-type {
      padding-top: 177px;
    }
  }

  .ddp-box-popupcontents {
    height: calc(100% - 80px);

    &.type-info {
      padding-bottom: 40px;
      box-sizing: border-box;

      .ddp-box-synch {
        position: absolute;
        bottom: 0;
        left: 0;
        right: 0;
      }
    }
  }

  .ddp-box-source-create.ddp-box-type .ddp-wrap-edit3:first-of-type {
    margin-top: 0;
  }
}

/* 가변 */

.ddp-type-contents-in {
  &.ddp-size-resize {
    height: 100%;

    .ddp-popup-dashboard {
      height: 100%;
      padding-bottom: 18px;
      box-sizing: border-box;
    }

    .ddp-wrap-variable {
      height: 100%;
      margin-top: -43px;
      padding-top: 43px;
      box-sizing: border-box;
    }

    .ddp-wrap-viewtable {
      height: 100%;

      &.ddp-add {
        position: relative;
        padding-bottom: 44px;
        box-sizing: border-box;
      }
    }

    .ddp-popup-dashboard {
      .ddp-wrap-variable .ddp-wrap-grid {
        height: 100%;
      }

      .ddp-wrap-grid .ddp-box-viewtable {
        height: 100%;
        min-height: auto;
      }
    }

    .ddp-wrap-viewtable.ddp-add .ddp-box-add-link3 {
      position: absolute;
      bottom: 1px;
      left: 0;
      right: 0;
    }

    .ddp-box-grid-detail {
      bottom: 19px;
    }

    .ddp-ui-bottomoption {
      bottom: 0;
      top: inherit;
    }

    .ddp-ui-import-option {
      position: relative;
      height: 100%;
      padding-bottom: 0;
      box-sizing: border-box;

      .ddp-ui-grid {
        height: 100%;
      }
    }

    .ddp-box-popupcontents.ddp-info {
      padding-bottom: 18px;
      box-sizing: border-box;
    }

    .ddp-dataflow .ddp-box-viewtable {
      height: 100%;
    }

    .ddp-dataset {
      margin-top: -95px;
      padding: 95px 0 0 0;
      box-sizing: border-box;
    }

    .ddp-ui-import-option.ddp-selected-query .ddp-dataset {
      margin-top: -358px;
      padding-top: 358px;
    }
  }

  &.ddp-size-resize2 {
    height: 100%;

    .ddp-popup-dashboard,
    .ddp-wrap-variable,
    .ddp-popup-dashboard .ddp-wrap-grid {
      height: 100%;
    }

    .ddp-wrap-file-navi,
    .ddp-wrap-sheet {
      height: 100%;
      box-sizing: border-box;
    }

    .ddp-cluster-add {
      height: 100%;
      box-sizing: border-box;

      .ddp-box-application {
        height: 100%;
        box-sizing: border-box;
      }

      .ddp-wrap-ex {
        height: 100%;
        box-sizing: border-box;
        overflow: auto;
      }
    }
  }

  &.ddp-sheet-resize {
    height: 100%;

    .ddp-wrap-variable {
      height: 100%;
      padding: 259px 0 0 0;
      margin-top: -254px;
      box-sizing: border-box;
    }

    .ddp-flex .ddp-wrap-variable {
      padding: 0;
      margin: 0;
      height: auto;
      flex: 1 auto;
      overflow: hidden;
    }

    .ddp-wrap-message {
      padding-top: 5px;
    }

    .ddp-wrap-variable {
      .ddp-wrap-sheet {
        height: 100%;
        box-sizing: border-box;
      }

      &.ddp-type {
        margin-top: -145px;
        padding: 155px 0 0 0;
      }
    }
  }

  &.ddp-size-resize3 {
    height: 100%;

    .ddp-box-popupcontents3 {
      height: 100%;
    }

    .ddp-wrap-variable {
      height: 100%;
      margin-top: -124px;
      padding: 124px 0 0 0;
      box-sizing: border-box;

      .ddp-box-schema {
        height: 100%;
        box-sizing: border-box;

        .ddp-wrap-fleft,
        .ddp-wrap-fright {
          height: 100%;
          box-sizing: border-box;
        }

        .ddp-box-viewtable {
          min-height: inherit;
        }
      }
    }
  }
}

.ddp-wrap-datadetail.ddp-size-resize {
  height: 100%;
  margin-top: -48px;
  padding: 63px 45px 0 45px;
  box-sizing: border-box;

  .ddp-wrap-tab-contents {
    height: 100%;
    margin-top: -57px;
    padding: 57px 0 50px 0;
    box-sizing: border-box;
  }

  .ddp-ui-tab-contents.ddp-full {
    height: 100%;
  }

  .ddp-wrap-variable {
    height: 100%;
    margin-top: -67px;
    padding: 67px 0 0 0;
    box-sizing: Border-box;

    .ddp-data-details {
      height: 100%;

      .ddp-wrap-detail {
        height: 100%;
        box-sizing: Border-box;
        overflow: auto;
      }
    }

    .ddp-wrap-sourcename {
      overflow: auto;
    }
  }
}

/**************************************************************
    네비
**************************************************************/

.ddp-layout-chart .ddp-wrap-naviarea {
  border: 1px solid #ececef;
  background-color: #fff;
}

.ddp-wrap-naviarea {
  display: flex;
  align-items: center;
  height: 48px;
  background-color: #e7e7ea;
  z-index: 35;
  box-sizing: border-box;

  em {
    margin-left: 14px;
  }
}

.ddp-ui-naviarea {
  display: block;
  padding: 7px 0 9px 0;
  overflow: hidden;

  &:before {
    display: table;
    content: '';
  }

  &:after {
    display: table;
    content: '';
    clear: both;
  }

  .ddp-wrap-naviname {
    display: inline-block;
    position: relative;
    top: 5px;
    float: left;
    max-width: 60%;
    font-size: 16px;
    color: #4b515b;
    box-sizing: border-box;
    line-height: 1em;

    &.ddp-full {
      max-width: 100%;
    }

    .ddp-wrap-popup2 {
      display: none;
      position: absolute;
      top: 100%;
      left: -15px;
    }
  }
}

.ddp-wrap-naviname.ddp-selected .ddp-wrap-popup2 {
  display: block;
}

.ddp-ui-naviarea {
  .ddp-wrap-naviname {
    .ddp-wrap-popup2.ddp-type {
      width: 245px;
    }

    span.ddp-data-naviname {
      display: inline-block;
      position: relative;
      height: 25px;
      padding: 0 18px 0px 5px;
      cursor: pointer;
      overflow: hidden;
      white-space: nowrap;
      word-wrap: normal;
      box-sizing: border-box;
      font-family: sans-serif;
      line-height: 1em;

      em.ddp-icon-menuview {
        display: none;
      }

      &.ddp-readonly {
        padding-right: 10px;
        cursor: default;
      }
    }
  }

  .ddp-wrap-naviname-txt span.ddp-data-naviname {
    display: inline-block;
    position: relative;
    width: 100%;
    padding: 2px 18px 2px 5px;
    cursor: pointer;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    word-wrap: normal;
    box-sizing: border-box;
  }

  .ddp-wrap-naviname {
    span.ddp-txt-naviname {
      display: inline-block;
      position: relative;
      width: 100%;
      padding: 2px 5px;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
      word-wrap: normal;
      box-sizing: border-box;
    }

    &:hover span.ddp-data-naviname {
      background-color: #d9dadf;

      &.ddp-readonly {
        background: none;
      }
    }

    &.ddp-type {
      span.ddp-data-naviname {
        cursor: auto;
      }

      &:hover span.ddp-data-naviname {
        background: none;
      }
    }
  }

  input.ddp-input-navi {
    float: left;
    padding: 3px 5px 2px 5px;
    width: 100%;
    height: 25px;
    font-size: 16px;
    background-color: #d9dadf;
    box-sizing: border-box;
    border: none;
    line-height: 1.3em;

    &::-webkit-input-placeholder,
    &:-ms-input-placeholder {
      color: #9ba0ab;
    }
  }

  .ddp-box-navi {
    .ddp-wrap-input {
      display: none;
      position: relative;
      top: 4px;
      float: left;
    }

    &.ddp-selected {
      .ddp-wrap-input {
        display: inline-block;
        position: relative;
        top: 5px;
        width: 40%;
        padding-right: 23px;
        box-sizing: border-box;
        vertical-align: middle;
      }

      &.ddp-check-none .ddp-wrap-input {
        padding-right: 0;

        .ddp-btn-check {
          display: none;
        }
      }
    }

    .ddp-wrap-input .ddp-btn-check {
      display: inline-block;
      position: absolute;
      top: 0;
      right: 0;
      height: 25px;
    }

    &.ddp-selected {
      input.ddp-input-navi {
        display: block;
      }

      .ddp-wrap-naviname span.ddp-data-naviname {
        display: none;
      }
    }
  }

  .ddp-wrap-naviname {
    span.ddp-data-naviname .ddp-icon-edit2 {
      display: none;
    }

    &:hover span.ddp-data-naviname {
      .ddp-icon-edit2 {
        display: block;
        position: absolute;
        top: 50%;
        right: 4px;
        margin-top: -6px;
        background-position: -11px top;
      }

      &.ddp-readonly .ddp-icon-edit2 {
        display: none;
      }
    }
  }

  .ddp-box-navidet {
    float: left;
    position: relative;
    top: 5px;
    max-width: 40%;
    box-sizing: border-box;
  }

  .ddp-wrap-navidet {
    display: inline-block;
    position: relative;
    float: left;
    max-width: 100%;
    white-space: nowrap;
    box-sizing: border-box;
    overflow: hidden;

    span {
      &.ddp-data-navidet {
        display: inline-block;
        float: left;
        width: 100%;
        height: 25px;
        padding: 5px;
        color: #90969f;
        font-size: 12px;
        cursor: pointer;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        word-wrap: normal;
        box-sizing: border-box;
        font-family: sans-serif;
        letter-spacing: 0.4px;
      }

      &.ddp-txt-navidet {
        display: inline-block;
        float: left;
        position: relative;
        top: 3px;
        width: 100%;
        padding: 2px 5px;
        color: #90969f;
        font-size: 12px;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        word-wrap: normal;
        box-sizing: border-box;
      }
    }

    em.ddp-icon-edit2 {
      display: none;
    }
  }

  .ddp-box-navidet {
    .ddp-wrap-input {
      display: none;
      position: relative;
      padding-right: 23px;
      box-sizing: border-box;

      .ddp-btn-check {
        display: inline-block;
        position: absolute;
        top: 0;
        right: 0;
        height: 25px;
      }
    }

    input.ddp-input-navi {
      display: inline-block;
      width: 100%;
      height: 25px;
      padding: 3px 5px 3px 4px;
      font-size: 12px;
      border: 1px solid #d9dadf;
      background-color: #d9dadf;
      box-sizing: border-box;
      font-family: sans-serif;
      letter-spacing: 0.4px;

      &::-webkit-input-placeholder {
        color: #9ba0ab;
        font-size: 12px;
      }

      &:-ms-input-placeholder {
        font-family: 'SpoqaHanSans';
        color: #9ba0ab;
      }
    }
  }

  .ddp-wrap-navidet {
    &:hover span.ddp-data-navidet {
      padding-right: 18px;
      background-color: #d9dadf;
    }

    span.ddp-data-navidet em.ddp-icon-edit2 {
      position: absolute;
      top: 50%;
      right: 4px;
      margin-top: -6px;
    }

    &:hover span.ddp-data-navidet em.ddp-icon-edit2 {
      display: block;
      background-position: -11px top;
    }

    span.ddp-data-navidet.ddp-readonly {
      cursor: default;

      em.ddp-icon-edit2 {
        display: none;
      }

      padding-right: 0;
      background: none;
    }

    &:hover span.ddp-data-navidet.ddp-readonly {
      padding-right: 0;
      background: none;
    }
  }

  .ddp-box-navidet.ddp-selected {
    width: 59%;

    .ddp-wrap-input,
    textarea.ddp-input-navi {
      display: block;
    }

    .ddp-wrap-navidet {
      display: none;
    }
  }
}

.ddp-wrap-naviarea {
  .ddp-ui-navidata {
    position: relative;
    padding-right: 16px;

    /* z-index: 1; */

    span {
      &.ddp-data-time {
        float: left;
        padding-right: 13px;
        color: #90969f;
        font-size: 12px;
        line-height: 48px;
        font-weight: 300;

        em {
          font-weight: normal;
        }
      }

      &.ddp-data-source {
        position: relative;
        float: left;
        padding: 15px 21px;
        color: #838793;
        font-size: 12px;

        &:before {
          display: inline-block;
          content: '';
          position: absolute;
          top: 15px;
          left: 0;
          height: 17px;
          border-left: 1px solid #d0d1d8;
        }

        a.ddp-icon-source3 {
          margin-right: 0;
          background-position: -34px -16px;

          &:hover {
            background-position: -17px -16px;
          }
        }

        .ddp-ui-tooltip-info {
          top: 37px;
          right: 11px;
        }
      }

      &.ddp-data-sec {
        float: left;
        padding: 15px 20px 16px 0;

        a {
          color: #646b72;
          font-size: 12px;

          em.ddp-icon-redo {
            display: inline-block;
            width: 18px;
            height: 16px;
            background: url(/assets/bi/images/icon_redo2.png) no-repeat;
          }

          &:hover {
            color: #535962;

            em.ddp-icon-redo {
              background-position: -19px top;
            }
          }
        }
      }
    }

    &.ddp-type .ddp-ui-work-buttons:before {
      display: none;
    }
  }

  .ddp-ui-work-buttons {
    position: relative;
    float: left;
    padding: 16px 0 0 23px;

    &:before {
      display: inline-block;
      position: absolute;
      top: 15px;
      left: 0;
      height: 17px;
      border-left: 1px solid #d0d1d8;
      content: '';
    }

    a {
      &[class*='ddp-icon-'] {
        color: #646b72;
        font-size: 12px;

        &:hover {
          color: #535962;
        }
      }

      &.ddp-icon-more {
        font-size: 0px;
      }

      &.ddp-icon-board-monitoring,
      &.ddp-icon-board-edit {
        float: left;
      }
    }

    .ddp-ui-more {
      float: left;
    }

    a {
      &.ddp-icon-board-monitoring {
        margin-right: 21px;
      }

      &.ddp-icon-board-edit {
        margin-right: 18px;
      }
    }

    .ddp-ui-more {
      position: relative;

      a.ddp-icon-more {
        &:before {
          background-position: left -12px;
        }

        &:hover:before {
          background-position: left -4px;
        }
      }

      &.ddp-selected {
        .ddp-wrap-popup2 {
          display: block;
          z-index: 15;
        }

        a.ddp-icon-more:before {
          background-position: 0 -4px;
        }

        &:hover .ddp-ui-tooltip-info {
          display: none;
        }
      }

      .ddp-wrap-popup2 {
        display: none;
        position: absolute;
        top: 24px;
        right: 0;
        min-width: 250px;
      }
    }
  }
}

.ddp-ui-more .ddp-ui-tooltip-info.ddp-down em.ddp-icon-view-top {
  right: 20px;
}

a.ddp-link-naviarea em.ddp-icon-navi {
  display: inline-block;
  position: absolute;
  top: 50%;
  left: 22px;
  width: 17px;
  height: 16px;
  margin: -8px 0 0 0;
  background: url(/assets/bi/images/btn_back.png) no-repeat;
}

/* 네비 (워크북/워크벤치) */

.ddp-wrap-naviarea {
  &.ddp-naviarea2 {
    padding-left: 55px;
    padding-right: 10px;

    .ddp-ui-naviarea .ddp-box-navidet {
      padding-left: 0;
    }

    &.ddp-type {
      padding-right: 80px;
    }
  }

  a.ddp-link-naviarea {
    display: inline-block;
    position: absolute;
    top: 0;
    left: 0;
    width: 50px;
    height: 47px;
  }

  .ddp-box-tag {
    display: inline-block;
    float: left;
    margin: 16px 8px 0 0;
  }
}

/* 네비 (edit) */

.ddp-ui-navibuttons {
  position: absolute;
  top: 7px;
  right: 0;
  padding-right: 12px;

  a {
    &.ddp-btn-undo {
      display: inline-block;
      margin-left: 19px;
      width: 21px;
      height: 14px;
      background: url(/assets/bi/images/btn_redo.png) no-repeat;
      vertical-align: middle;
    }

    &.ddp-btn-redo {
      display: inline-block;
      margin-left: 19px;
      width: 21px;
      height: 14px;
      background: url(/assets/bi/images/btn_redo.png) no-repeat;
      vertical-align: middle;
      transform: rotate(180deg);
      margin-right: 16px;
    }

    &.ddp-btn-undo:hover {
      background-position: left -15px;
    }

    &.ddp-btn-redo:hover {
      transform: rotate(180deg);
      background-position: left -15px;
    }

    &.ddp-btn-undo.ddp-disabled,
    &.ddp-btn-redo.ddp-disabled,
    &.ddp-btn-undo.ddp-disabled:hover,
    &.ddp-btn-redo.ddp-disable:hover {
      opacity: 0.4;
      cursor: no-drop;
      background-position: left top;
    }

    &.ddp-btn-line3 {
      display: inline-block;
      padding: 7px;
      margin-left: 5px;
      min-width: 103px;
      border: 1px solid #c5c7cc;
      border-radius: 2px;
      text-align: center;
      color: #545b65;
      font-size: 12px;
      font-weight: bold;
    }

    &.ddp-btn-buttons2 {
      margin-left: 5px;
    }

    &.ddp-btn-line3 {
      &:hover {
        border: 1px solid #a1a6ad;
        color: #4b515b;
      }

      &.ddp-disabled {
        border: 1px solid #c5c7cc;
        opacity: 0.5;
        cursor: no-drop;
      }
    }

    &.ddp-btn-blank {
      display: inline-block;
      padding: 10px 22px;
      margin-right: -12px;

      em {
        margin-right: 0;
      }
    }
  }
}

.ddp-wrap-naviarea.ddp-editnavi {
  padding-right: 400px;

  &.ddp-board-naviarea {
    padding-right: 780px;
  }

  .ddp-ui-naviarea {
    white-space: normal;
    text-overflow: inherit;
    overflow: inherit;

    .ddp-wrap-naviname:hover span.ddp-data-naviname,
    .ddp-wrap-navidet:hover span.ddp-data-navidet {
      background: none;
    }

    .ddp-wrap-naviname span.ddp-data-naviname.ddp-readonly {
      padding-right: 10px;
    }
  }
}

.ddp-ui-naviarea .ddp-wrap-naviname em.ddp-icon-menuview {
  display: inline-block;
  position: absolute;
  top: 50%;
  right: 14px;
  width: 9px;
  height: 5px;
  margin-top: -3px;
  background: url(/assets/bi/images/icon_menuview.png) no-repeat;
  background-position: left -14px;
}

app-navigation {
  z-index: 2 !important;
}

input:-webkit-autofill {
  background-color: red !important;
}

// .ace_line {
//   float: left;

//   &.ace_selected {
//     float: left;
//     padding-right: 5px;
//   }
// }
